import React, {Component, useState, useEffect} from 'react';
import {Container} from "../../../../components";
import styles from './News.module.scss';
import {action, computed, makeObservable, observable, runInAction, toJS} from "mobx";
import {observer, Observer} from "mobx-react";
import API from "../../../../services/api";
import {Classes} from "../../../../utils";
import Spinner from "../../../../components/Spinner";


import close from './assets/svg/times.svg';


class NewsStore {
    constructor() {
        makeObservable(this, {
            _news: observable,
            query: observable,
            _pages: observable,
            loading: observable,
            news: computed,
            pages: computed,
            getData: action,
            _onEndReached: action,
            changePage: action,
        })
    }

    _news = [];

    get news() {
        return this._news.slice();
    }

    query = {
        page: 1,
        limit: 6,
    };
    loading = true;
    _pages = 1;

    get pages() {
        return this._pages;
    }

    list = React.createRef();


    getData = async (page = 1) => {
        try {
            runInAction(() => {
                this.loading = true;
            });
            const {data} = await API.get('public/news', {
                params: {...toJS(this.query)},
                page,
            });
            data.docs = data.docs || [];

            runInAction(() => {
                this._news = [];
                this._news =
                    page === 1 ? data.docs : [...toJS(this._news), ...data.docs];
                this.query.page = page;
                this._pages = data.totalPages;
            });
        } catch (error) {
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };


    _onEndReached = () => {
        if (!this.loading && this.query.page < this._pages) {
            this.loading = true;
            this.getData(this.query.page + 1);
        }
    };
    changePage = (page) => {
        if (page !== this.query.page) {
            this.query.page = page;
            this.getData(page)
        }
    };
}

@observer
class News extends Component {
    newsStore = new NewsStore();

    componentDidMount() {
        this.newsStore.getData(1);
    }

    renderItem = (item) => {
        return (
            <NewsItem key={item._id} item={item}/>
        )
    }

    liPages = (pages = 1) => {
        let pagesArr = [];
        for (let i = 1; i <= pages; i++) {
            pagesArr.push(i);
        }
        return pagesArr;
    }

    render() {
        return (
            <Container className={styles.component}>
                <div className="flex j-center">
                    <h2>Новости</h2>
                </div>
                <div className="flex wrap j-center mt-4 mb-4">
                    {this.newsStore.loading ? <Spinner/> : this.newsStore.news ?
                        this.newsStore.news.map(this.renderItem)
                        : 'Список новостей пуст'
                    }

                </div>
                <div className="flex j-center">
                    <ul className={Classes.join([styles.pagination, 'flex'])}>
                        {
                            this.liPages(this.newsStore.pages) &&
                            this.liPages(this.newsStore.pages).map((item, index) => (
                                <li
                                    onClick={() => this.newsStore.changePage(item)}
                                    className={Classes.join([this.newsStore.query.page === item ?
                                        styles.active : '', 'mr-1'])} key={index}
                                >
                                    {item}
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </Container>
        )
    }
}

const NewsItem = observer(({item}) => {
        const [show, setShow] = useState(false);
        useEffect(() => {
            show && (document.body.style.overflow = 'hidden');
            !show && (document.body.style.overflow = 'auto');
        }, [show]);

        return (
            <>
                <div className={Classes.join([styles.newsItem, 'mb-3 mr-3'])}
                     onClick={() => setShow(true)}
                >
                    <img src={item.image_url} alt={item.title}/>
                    <h5 className='mt-25 mr-2 mb-25 ml-2'>{item.title}</h5>
                </div>
                {show && <NewsMore item={item} setShow={setShow}/>}
            </>
        )
    }
)

const NewsMore = observer(({item, setShow}) => {
        return (
            <div className={Classes.join([styles.newsModal, 'flex j-center a-center'])}>
                <div className={Classes.join([styles.modalContent])}>
                    <img
                        onClick={() => setShow(false)}
                        className={Classes.join([styles.close, 'mb-2'])} src={close} alt="close"/>
                    <img
                        src={item.image_url} alt={item.title}/>
                    <h4 className='mt-4 mb-2'>{item.title}</h4>
                    <hr/>
                    <p className='mt-2'>
                        {item.body}
                    </p>
                </div>
                <div className={styles.modalBack} onClick={() => setShow(false)}></div>
            </div>
        )
    }
)
export default News;