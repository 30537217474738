import React from 'react';
import {Banner, Contacts as OurContacts, ContactForm, Filials} from '../utils/components';

const Contacts = () => {
  return (
    <>
      <Banner />
      {/*<OurContacts />*/}
      <Filials />
      <ContactForm />
    </>
  );
};

export default Contacts;
