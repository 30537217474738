import React, {Component} from 'react';
import styles from "./Profile.module.scss";
import {CalendarItem, CalendarYears, UserPanel} from "../utils/components";
import store from "../../../services/store";
import {Redirect, Route} from 'react-router-dom';
import {History, Payments, ResetPassword} from "../index";
import Classes from "../../../utils/classes";
import Notification from "../utils/components/Notification/Notification";
import Spinner from "../../../components/Spinner";
import ChangeLanguage from "../utils/components/ChangeLanguage/ChangeLanguage";
import FeedbackForm from "../Feedback/FeedbackForm";
import FeedbackList from "../FeedbackList/FeedbackList";
import Calculator from "../Calculator/Calculator";
import MediaQuery from "react-responsive/src/Component";

export default class Profile extends Component {

    render() {
        const {match: {url}} = this.props;
        const pages = {
            '/cabinet': <Payments/>,
            '/cabinet/payments': <Payments/>,
            '/cabinet/history': <History/>,
            '/cabinet/notifications': <Notification/>,
            '/cabinet/resetPassword': <ResetPassword/>,
            '/cabinet/changeLanguage': <ChangeLanguage/>,
            '/cabinet/calculator': <Calculator/>,
            '/cabinet/feedback': <FeedbackForm/>,
            '/cabinet/feedbackList': <FeedbackList/>,
        }
        const lc = localStorage.getItem('refresh_token');
        // if (!lc) {
        //     return <Redirect to="/cabinet/login"/>
        // }
        if (!store.refresh_token) {
            return <Redirect to="/cabinet/login"/>
        }
        return (
            <div className={Classes.join([styles.component, 'container flex j-start mt-6 mb-14x'])}>
                <MediaQuery minWidth={640}>
                    <div>
                        <UserPanel/>
                    </div>
                </MediaQuery>
                <div>
                    {
                        pages[url]
                    }
                </div>

            </div>
        )
    }
}