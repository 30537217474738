import React, {useState} from 'react';
import styles from './NotificationItem.module.scss';
import {Classes} from "../../../../../utils";

import arrow from './assets/svg/arrow.svg';

const NotificationItem = ({item}) => {
    const [show, setShow] = useState(false);

    return (
        <React.Fragment>
            <div className={Classes.join([styles.component, 'flex j-between a-center pt-2 pr-2 pl-2 pb-15 mt-2'])}
                 onClick={() => {
                     setShow((s) => !s)
                 }}
            >
                <div className={styles.info}>
                    <p className={Classes.join([styles.date, 'mb-2'])}> {item.title}</p>
                    <p className="flex a-center column">
                        <span className="mb-1"> {item.date.formatDate('DD MMMM YYYY, HH:mm')}</span>
                    </p>
                </div>
                <img src={arrow} className={Classes.join([styles.arrow, show ? styles.active : ''])}/>
            </div>
            {show && <NotificationPopup setShow={setShow} item={item}/>}
        </React.Fragment>
    )
}


const NotificationPopup = ({item, setShow}) => {

    return (
        <div className={Classes.join([styles.popup, 'flex j-between a-center pt-2 pr-2 pl-2 pb-15'])}>
            <div className={Classes.join([styles.info, 'pt-4 pb-4 pl-4 pr-2'])}>
                <p className={Classes.join([styles.date, 'mb-2'])}> {item.title}</p>

                <p className="flex column">
                    {item.image_url && <img src={item.image_url}/>}
                    <span className="mb-1"> {item.date.formatDate('DD MMMM YYYY, HH:mm')}</span>
                    <span>{item.body}</span>
                </p>
            </div>
            <div className={styles.popupBack}></div>
        </div>
    )

}


export default NotificationItem;