// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8k89YBvEQ359oTP2LlovAw\\=\\={background:#fff;box-shadow:0px 4px 26px rgba(0,0,0,.06);border-radius:10px;max-width:270px;min-width:220px}._8k89YBvEQ359oTP2LlovAw\\=\\= div:first-child img{max-width:90px}._8k89YBvEQ359oTP2LlovAw\\=\\= div:first-child p{font-weight:bold;font-size:18px;line-height:22px}._8k89YBvEQ359oTP2LlovAw\\=\\= a{font-weight:bold;font-size:18px;line-height:22px;color:rgba(0,0,0,.5);text-decoration:none}._8k89YBvEQ359oTP2LlovAw\\=\\= a._7UdtxDcQ43lLfbV2Y9Oo-A\\=\\={color:#000}", "",{"version":3,"sources":["webpack://./src/views/cabinet/utils/components/UserPanel/UserPanel.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CACA,uCAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CAGI,iDACE,cAAA,CAEF,+CACE,gBAAA,CACA,cAAA,CACA,gBAAA,CAIN,+BACE,gBAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CACA,oBAAA,CACA,2DACE,UAAA","sourcesContent":[".component{\r\n  background: #FFFFFF;\r\n  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);\r\n  border-radius: 10px;\r\n  max-width: 270px;\r\n  min-width: 220px;\r\n  & div{\r\n    &:first-child{\r\n      img{\r\n        max-width: 90px;\r\n      }\r\n      p{\r\n        font-weight: bold;\r\n        font-size: 18px;\r\n        line-height: 22px;\r\n      }\r\n    }\r\n  }\r\n  a{\r\n    font-weight: bold;\r\n    font-size: 18px;\r\n    line-height: 22px;\r\n    color: rgba(0, 0, 0, 0.5);\r\n    text-decoration: none;\r\n    &.active{\r\n      color: rgba(0, 0, 0, 1);\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "_8k89YBvEQ359oTP2LlovAw==",
	"active": "_7UdtxDcQ43lLfbV2Y9Oo-A=="
};
export default ___CSS_LOADER_EXPORT___;
