import React, { Component } from 'react';
import styles from "../utils/components/News/News.module.scss";
import { Button, Container, Form, Input } from "../../components";
import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import store from "../../services/store";
import API from "../../services/api";
import { observer } from "mobx-react";
import PhoneInput from "../../components/PhoneInput/PhoneInput";
import { Link } from "react-router-dom";
import { PaymentCard } from "../cabinet/utils/components";
import FlatList from "flatlist-react";
import { withTranslation } from "react-i18next/hooks";

class PawnsPageStore {
    constructor() {
        makeObservable(this, {
            redirect: observable,
            form: observable,
            _item: observable,
            onChange: action,
            onPress: action,
            formInvalid: computed,
            item: computed
        })
    }

    _item = null;

    get item() {
        return this._item;
    }

    form = {
        // iin: '910307350461',
        // pawn_id: 'AQ00000002020-425',
        iin: '',
        pawn_id: '',
    };
    redirect = false;


    onChange = (field, value) => {
        this.form[field] = value.toUpperCase();
    };

    onPress = async () => {
        try {
            store.toggleLoading(true);
            const {data} = await API.get('public/pawns/find', {params: this.form});

            runInAction(() => {
                this._item = {...data};
            })

        } catch (error) {
        } finally {
            store.toggleLoading(false);

        }
    };

    get formInvalid() {
        return this.form.iin.length !== 12 || this.form.pawn_id.length === 0;
    }
}


@observer
class Pawns extends Component {
    pawnsStore = new PawnsPageStore();

    getItems = async () => {
        try {
            // const data = await this.props.listStore.getItems();
            await this.pawnsStore.onPress()
            // this.props.onGetItems && this.props.onGetItems(data);
        } catch (error) {
        }
    };

    render() {
        const {t} = this.props;
        return (
            <Container className={ styles.component }>

                <h2 className='mb-4'>{t('FindPawns')}</h2>
                <div className="flex j-center a-center column">
                    <Form theme="auth">
                        <Input onInput={ this.pawnsStore.onChange } type="text"
                               name="iin" placeholder={t('ENTER_IIN')}/>
                        <Input onInput={ this.pawnsStore.onChange } type="text"
                               name="pawn_id" placeholder={t('PAWN_ID')}/>
                        <div className="flex j-around a-center">
                            <Button
                                disabled={ this.pawnsStore.formInvalid }
                                onClick={ this.getItems }
                                theme="secondary" text={t('ENTER')}
                                type="submit" size="middle"/>
                        </div>
                    </Form>
                    {/*{ this.pawnsStore.items &&*/ }
                    {/*<FlatList list={ toJS(this.pawnsStore.items) }*/ }
                    {/*          renderItem={ this.renderItem }*/ }
                    {/*          renderWhenEmpty={ () => 'Список текущих платежей пуст' }*/ }
                    {/*          renderOnScroll*/ }
                    {/*/> }*/ }
                    <div className='mt-5'>
                        { this.pawnsStore.item &&
                        <PaymentCard isPublic={ true } item={ toJS(this.pawnsStore.item) }/> }
                    </div>
                </div>
            </Container>

        )
    }
}

export default withTranslation()(Pawns);

