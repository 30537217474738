import React from 'react';
import { Container } from '../../../../components';
import { Classes } from '../../../../utils';
import styles from './WhyUs.module.scss';
import {observer} from "mobx-react";
import publicAPI from "../../../../services/publicApi";
import store from "../../../../services/store";


const WhyUs = observer(() => {
  const loadWhyUs = async () => {
    const {data} = await publicAPI.get('/why-us');
    return data;
  }
  const [whyUs, setWhyUs] = React.useState([]);

  React.useEffect(() => {
    loadWhyUs().then(res => setWhyUs(res))
  }, [store.language])

  return(
  <Container className={styles.component}>
    <div className="flex j-center">
      <h2>{whyUs.title && (whyUs.title[store.language] ? whyUs.title[store.language] : whyUs.title.ru)}</h2>
    </div>
    <div className={Classes.join([styles.container, 'flex j-between mt-55'])}>
      <div className={styles.content}>
        <ul className={styles.list}>
          {whyUs.about_list && whyUs.about_list.map(({title, image, text}, index) => (
            <Feature
                title={title && (title[store.language] ? title[store.language] : title.ru)}
                image={`${process.env.REACT_APP_IMG_URL}${image ? image.url : ''}`}
                alt={title}
                text={text && (text[store.language] ? text[store.language] : text.ru)}
                     key={index} />
          ))}
        </ul>
      </div>
      <div className={styles.image}>
        <img src={`${process.env.REACT_APP_IMG_URL}${whyUs.image ? whyUs.image.url : ''}`} alt="why-us" />
      </div>
    </div>
  </Container>
)});

const Feature = ({ title, text, image, alt }) => (
  <li className={Classes.join([styles.item, 'flex a-start'])}>
    <img src={image} alt={alt} />
    <div className="flex column">
      <h6>{title}</h6>
      <p>{text}</p>
    </div>
  </li>
);

export default WhyUs;
