import React from 'react';
import {GoogleMap, Marker, InfoBox, useLoadScript} from "@react-google-maps/api";
import {FilialsStore} from "./Filials";
import {observer} from "mobx-react";

import logo from './assets/svg/logo-icon.svg';



const containerStyle = {
    width: '100%',
    height: '600px'
};


const FilialsMap = observer(({filials, center, zoom, setZoom }) => {

        const filialsStore = new FilialsStore();
        const {isLoaded} = useLoadScript({
            id: 'google-map-script',
            googleMapsApiKey: "AIzaSyDWGvV9cb-dm9_vhbz7jq-Le-xUUhtEQeE",
            language: 'ru'
        })

        const [map, setMapInfo] = React.useState(null)


        const onLoad = React.useCallback(function callback(map) {
            // const bounds = new window.google.maps.LatLngBounds();
            // map.fitBounds(bounds);
            map.setCenter(center);
            setMapInfo(map)

        }, [])


        const onUnmount = React.useCallback(function callback(map) {
            setMap(null)
        }, [])


        const renderMarker = (marker) => {
            return (
                <SMarker marker={marker} key={marker._id}/>
            )
        }

        function handleZoomChanged(){
           const zoom = this.getZoom();
           setZoom(zoom);
        }
        return (
            <div className='map'>
                <div className='map-container'>
                    {isLoaded  && <GoogleMap
                        id='data-example'
                        mapContainerStyle={containerStyle}
                        zoom={zoom}
                        center={center}
                        onLoad={onLoad}
                        onZoomChanged={handleZoomChanged}

                    >
                        {filials && filials.map(renderMarker)}
                    </GoogleMap>
                    }
                </div>
            </div>
        )
    }
)


const SMarker = observer(({marker}) => {
    const [show, setShow] = React.useState(false);
    const InfoOptions = {closeBoxURL: '', enableEventPropagation: true};
    const onClick = (...args) => {

        setShow((state) => {
            return !state;
        });
    }

    const {location: {coordinates}} = marker;
    return (
        <div key={marker._id}>
            <Marker

                icon={{url:logo, scaledSize: new google.maps.Size(70, 70)}}
                position={{
                    lat: coordinates[0],
                    lng: coordinates[1]
                }}
                onClick={onClick}
                // icon={logo}
                // label='test bla bla'
            />
            {show && <InfoBox
                options={InfoOptions}
                position={{
                    lat: coordinates[0],
                    lng: coordinates[1]
                }}
            >
                <div style={{backgroundColor: 'yellow', opacity: 0.75, padding: 12}}>
                    <div style={{fontSize: 16, fontColor: `#08233B`}}>
                        {marker.name}
                    </div>
                </div>
            </InfoBox>
            }
        </div>
    )
})

export default React.memo(FilialsMap);