import React, {useCallback, useMemo} from 'react';
import {Classes} from '../../utils';
import styles from './Input.module.scss';

const Input = (props) => {
    const onChange = useCallback(
        text => {
            props.onInput && props.onInput(props.name, text.target.value);
        },
        [props],
    );
    const invalid = useMemo(
        () =>
            props.invalid && props.value && props.value.length > 0
                ? styles.invalid
                : null,
        [props.invalid, props.value],
    );
    return (
        <div className={Classes.join([styles.component, invalid ? styles.invalid : null])}>
            <input value={props.value} type={props.type} name={props.name} onInput={onChange}
                   placeholder={props.placeholder} onChange={props.onChange}/>
            {invalid ? <small> {props.errorMessage ? props.errorMessage : 'Данное поле является обязательным!'}</small> : null}
        </div>
    );
};

export default Input;
