// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KHWyZj0qZ8HXOh6AFsa4AQ\\=\\={background:#fff;border:1px solid #d8d8d8;border-radius:10px;width:100%}.KHWyZj0qZ8HXOh6AFsa4AQ\\=\\= .h\\+XdodGza\\+9RmAeCwPuVpA\\=\\={font-weight:bold;font-size:20px;line-height:24px}.KHWyZj0qZ8HXOh6AFsa4AQ\\=\\= .h\\+XdodGza\\+9RmAeCwPuVpA\\=\\=+p span{font-size:14px;line-height:18px;color:rgba(0,0,0,.8)}.KHWyZj0qZ8HXOh6AFsa4AQ\\=\\= .mR6DUMGGsFye61Ct8vfQDg\\=\\={font-weight:bold;font-size:28px;line-height:34px;color:#090}@media(max-width: 680px){.KHWyZj0qZ8HXOh6AFsa4AQ\\=\\= .mR6DUMGGsFye61Ct8vfQDg\\=\\={font-size:18px}}", "",{"version":3,"sources":["webpack://./src/views/cabinet/utils/components/HistoryItem/HistoryItem.module.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,wBAAA,CACA,kBAAA,CAEA,UAAA,CACA,0DACE,gBAAA,CACA,cAAA,CACA,gBAAA,CAEA,iEACE,cAAA,CACA,gBAAA,CACA,oBAAA,CAGJ,wDACE,gBAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CACA,yBALF,wDAMI,cAAA,CAAA","sourcesContent":[".component{\r\n  background: #FFFFFF;\r\n  border: 1px solid #D8D8D8;\r\n  border-radius: 10px;\r\n  //max-width: 810px;\r\n  width: 100%;\r\n  .date{\r\n    font-weight: bold;\r\n    font-size: 20px;\r\n    line-height: 24px;\r\n\r\n    & + p span{\r\n      font-size: 14px;\r\n      line-height: 18px;\r\n      color: rgba(0, 0, 0, 0.8);\r\n    }\r\n  }\r\n  .value{\r\n    font-weight: bold;\r\n    font-size: 28px;\r\n    line-height: 34px;\r\n    color: #009900;\r\n    @media (max-width: 680px){\r\n      font-size: 18px;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "KHWyZj0qZ8HXOh6AFsa4AQ==",
	"date": "h+XdodGza+9RmAeCwPuVpA==",
	"value": "mR6DUMGGsFye61Ct8vfQDg=="
};
export default ___CSS_LOADER_EXPORT___;
