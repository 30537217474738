import React, { Component, PureComponent } from 'react';
import { Container, Form, Input } from "../../../../components";
import { Classes } from '../../../../utils';
import styles from './Filials.module.scss';
import { action, computed, makeObservable, observable } from "mobx";
import store from "../../../../services/store";
import API from "../../../../services/api";
import { toast } from "react-toastify";
import FilialsMap from "./FilialsMap";
import { geolocated } from "react-geolocated";
import { GoogleMap, InfoBox, Marker } from "@react-google-maps/api";

// const contacts = [
//   { contact: 'Instagram', meta: 'superlombard_kokshe', to: '/' },
//   { contact: 'Instagram', meta: 'superlombard_astana', to: '/' },
//   { contact: 'E-Mail', meta: 'info@superlombard.kz', to: 'maillto:info@superlombard.kz' },
//   { contact: 'Единый номер', meta: '+7 700 292-57-57', to: 'tel: +7 700 292-57-57' },
//   { contact: 'Головной офис', meta: 'г. Кокшетау, ул. Пушкина 14' },
//   { contact: 'тел.', meta: '8 (7162) 92-57-57', to: 'tel:8 (7162) 92-57-57' },
//   { contact: 'тел.', meta: '+77002925757', to: 'tel:+77002925757' },
//   { contact: 'Автоломбард', meta: 'г. Кокшетау: +7 702 921 48 61', to: 'tel:+7 702 921 48 61' },
//   { contact: 'Автоломбард', meta: 'г. Нур-Султан: +7 778 001 72 52', to: 'tel:+7 778 001 72 52' },
//   { contact: 'Автоломбард', meta: 'г. Алматы: +7 707 544 7657', to: 'tel:+7 707 544 7657' },
// ];

import clock from './assets/svg/clock.svg';
import phone from './assets/svg/phone.svg';
import logo from './assets/svg/logo-icon.svg';

import { observer } from "mobx-react";
import Spinner from "../../../../components/Spinner";
import { withTranslation } from "react-i18next/hooks";

class FilialsStore {
    constructor() {
        makeObservable(this, {
            _filials : observable,
            loading : observable,
            _coordinates : observable,
            _center : observable,
            _page : observable,
            filials : computed,
            coordinates : computed,
            page : computed,
            center : computed,
            getFilials : action,
            getCurrentPosition : action,
            onViewMap : action,
        })
    }

    _filials = [];
    loading = true;

    get filials() {
        return this._filials.slice();
    }

    set filials(v) {
        this._filials = v;
    }

    _coordinates = '51.147611,71.1992116';
    _center = {
        lat : 51.177443483892645,
        lng : 71.42069577973191,
    }

    get center() {
        return this._center;
    }

    set center(v) {
        this._center = v;
    }

    get coordinates() {
        return this._coordinates;
    }

    set coordinates(v) {
        this._coordinates = v;
    }

    _page = 0;
    get page() {
        return this._page;
    }

    set page(v) {
        this._page = v;
    }

    mapRef = React.createRef();


    getCurrentPosition = (coords) => {
        store.toggleLoading(true);
        try {
            const coord = {
                lat : coords ? coords.latitude : this._center.lat,
                lng : coords ? coords.longitude : this._center.lng,
            }

            this._center = coord;
        } catch (error) {
            console.log(error);
            store.toggleLoading(false);
        }
    };

    getFilials = async (coord) => {
        try {
            store.toggleLoading(true);
            this.loading = true;
            const { data } = await API.get('public/filials/nearest', {
                params : { coordinates : this.coordinates },
            });
            this.filials = data;
            this.getCurrentPosition(coord);
        } catch (error) {
            console.log(error);
        } finally {
            store.toggleLoading(false);
            this.loading = false;
        }
    };

    // viewOnMap = item => {
    //     this.page = 1;
    //     this.mapRef.current.viewOnMap(item);
    // };

    // onChangeTab = ({i}) => {
    //     this.page = i;
    // };

    onViewMap = (id) => {
        const item = this._filials.find(el => el._id === id);
        const coord = {
            lat : item.location.coordinates[0],
            lng : item.location.coordinates[1],
        }
        this._center = coord;
    }

}

@observer
class Filials extends PureComponent {
    filiaslsStore = new FilialsStore();
    myRef = React.createRef()

    state = {
        zoom : 10,
        term : '',
    }

    componentDidMount() {
        this.filiaslsStore.getFilials(this.props.coords);
    }


    renderItem = (item) => (
        <FilialItem key={ item._id } id={ item._id } item={ item }
                    onViewMap={ this.executeScroll } setZoom={ this.onSetZoom }/>
    )

    onSetZoom = (v) => {
        this.setState({ zoom : v })
    }
    executeScroll = (id) => {
        this.myRef.current.scrollIntoView()
        this.filiaslsStore.onViewMap(id);
    }

    search(items, term) {
        if (term.length === 0) {
            return items;
        }

        return items.filter((item) => {
            return item.address.toLowerCase().indexOf(term.toLowerCase()) > -1;
        })
    }

    onSearchChange = (term) => {
        this.setState({ term })
    }

    render() {
        const { visible = true } = this.props;
        const { term } = this.state;
        const visibleItems = this.search(this.filiaslsStore.filials, term);
        const { t } = this.props;
        if (this.filiaslsStore.loading) {
            return <Spinner/>
        }

        return (
            <Container className={ styles.component }>
                <div className="flex j-center">
                    <h2>{ t('FILIALS_PAGE') }</h2>
                </div>
                { visible && <Form theme='auth'>
                    <Input onInput={ (e, v) => this.setState({ term : v }) } type="text"
                           name="search" placeholder={ t('INPUT_CITY') }/>
                </Form> }
                { visible && <div className={ Classes.join([styles.container, 'flex j-center wrap mt-75']) }>
                    { this.filiaslsStore.filials && visibleItems.map(this.renderItem) }
                </div> }

                { this.filiaslsStore.filials &&
                <div ref={ this.myRef }>
                    {
                        !this.props.isGeolocationAvailable ? (
                            <div>Ваш браузер не поддерживает работу с Геолокацией</div>
                        ) : !this.props.isGeolocationEnabled ? (
                            <div style={ { textAlign : 'center' } }>Геолокация не включена</div>
                        ) : this.props.coords ? (
                            <FilialsMap filials={ this.filiaslsStore.filials } center={ this.filiaslsStore.center }
                                        zoom={ this.state.zoom } setZoom={ this.onSetZoom }/>
                        ) : (
                            <div style={ { textAlign : 'center' } }>Разрешите доступ к Вашей Геолокации</div>
                        )
                    }
                </div>
                }

            </Container>
        );
    }
}

const FilialItem = observer(({ item, id, onViewMap, setZoom }) => (
        <div className={ Classes.join([styles.filialItem, 'pt-2 pb-2 pr-4 pl-4 mb-3']) }
             onClick={ () => {
                 setZoom(15);
                 onViewMap(id)
             } }
        >
            <div className={ styles.address }>{ item.address }</div>
            <div className={ Classes.join([styles.info, "flex mb-2 mt-2"]) }>
                <p className='mr-2'><img className='mr-1' src={ clock } alt="clock"/> { item.timetable }</p>
                {/*<p><img className='mr-1' src={phone} alt="phone"/> 8 (7162) 92-57-57 вн. 201</p>*/ }
            </div>
            <div className={ styles.text }>
                Во время карантина время работы может измениться, уточняйте по указанному номеру.
            </div>
        </div>
    )
)


export default geolocated({
    positionOptions : {
        enableHighAccuracy : false,
    },
    userDecisionTimeout : 10000,
})(withTranslation()(Filials));
export { FilialsStore };
