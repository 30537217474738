import React, { useState } from 'react';
import styles from './PaymentCard.module.scss';
import arrow from './assets/svg/chevron-down.svg';
import { Classes } from '../../../../../utils';
import { Button } from '../../../../../components';
import { computed, makeObservable, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import API from '../../../../../services/api';
import store from '../../../../../services/store';
import { withTranslation } from 'react-i18next/hooks';

class PaymentStore {
  constructor() {
    makeObservable(this, {
      _item: observable,
      item: computed,
      canPay: computed,
    });
  }

  _item = null;
  get item() {
    return this._item;
  }

  set item(v) {
    this._item = v;
  }

  get canPay() {
    try {
      return Number(this.item.Payment_TA) > 0;
    } catch (error) {
      return false;
    }
  }
}

const paymentStore = new PaymentStore();

const PaymentCard = observer(({ item, isPublic = false, t }) => {
  const [show, setShow] = useState(false);

  const loadPayment = async () => {
    try {
      store.toggleLoading(true);
      paymentStore.item = item;
      setShow((s) => !s);
    } catch (error) {
    } finally {
      store.toggleLoading(false);
    }
  };
  return (
    <div className={Classes.join([styles.card, 'mb-3'])}>
      <div className={Classes.join([styles.component, 'flex j-between mb-3'])} onClick={loadPayment}>
        <div className="flex j-between">
          <div className="flex column j-between mr-4">
            <div className={Classes.join([styles.title, 'mb-15'])}>{item.tariff}</div>
            <div className={styles.date}>
              {t('NEXT_PAYMENT')} {item.Date_TA?.formatDate('DD.MM.YYYY')}
            </div>
          </div>
          <div className="flex column j-between">
            <div className={Classes.join([styles.price, 'mb-15'])}> {item.pawn_amount?.format()} ₸</div>
            <div className={styles.frequency}>{Number(item.Payment_TA)?.format()} ₸</div>
          </div>
        </div>
        <img className={Classes.join([show ? styles.show : '', 'ml-3'])} src={arrow} alt="Подробнее" />
      </div>
      {show && <PaymentDetails t={t} isPublic={isPublic} item={toJS(item)} />}
    </div>
  );
});
const PaymentDetails = (props) => {
  const { item, isPublic = false, t } = props;
  const makePayment = async () => {
    try {
      store.toggleLoading(true);

      const {
        data: { link },
      } = await API.post('payments/card/v1/getLink', {
        iin: item.iin,
        pawnId: isPublic ? item._id : paymentStore.item._id,
      });

      window.open(link, '_self');
    } catch (error) {
      console.log(error);
    } finally {
      store.toggleLoading(false);
    }
  };
  return (
    <div className={styles.details}>
      <div>
        <div className={Classes.join([styles.title, 'mb-45'])}>{item.tariff}</div>
        <div className="flex column a-center mb-4">
          <span className={Classes.join([styles.nums, ' mb-15'])}>{t('PAWN_AMOUNT')}</span>
          <div className={styles.price}>{item.pawn_amount.format()} ₸</div>
        </div>
        <div className={Classes.join([styles.more, 'pb-3 pl-3 pr-3 pt-3'])}>
          <div className={styles.title}>{t('PAWN_DETAIL')}</div>
          <div className={Classes.join([styles.value, 'flex  j-between mb-15'])}>
            <span>{t('PAWN_TARIFF')}:</span>
            <div>
              {item.tariff} {item.tariffProcent}%
            </div>
          </div>
          <div className={Classes.join([styles.value, 'flex j-between mb-15'])}>
            <span>{t('PAWN_ID')}:</span>
            <div>{item.pawn_id}</div>
          </div>
          <div className={Classes.join([styles.value, 'flex j-between mb-15'])}>
            <span>{t('PAWN_CREATION_DATE')}:</span>
            <div>{item.creation_date.formatDate('DD.MM.YYYY')}</div>
          </div>
          <div className={Classes.join([styles.value, 'flex j-between mb-15'])}>
            <span>{t('PAWN_ITEMS')}:</span>
            {item.items.map((x, idx) => (
              <div key={idx} className={Classes.join([styles.laid, 'pl-15 pr-15'])}>
                {x.name} - {x.price.format()} ₸
              </div>
            ))}
          </div>
          <div className={Classes.join([styles.value, 'flex j-between mb-15'])}>
            <span>{t('PAWN_NEXTPAYMENT_DATE')} :</span>
            <div>{item.Pay_Before.formatDate('DD.MM.YYYY')}</div>
          </div>
          <div className={Classes.join([styles.value, 'flex j-between mb-15'])}>
            <span>{t('PAWN_CREATION_DATE')} :</span>
            <div>{item.creation_date.formatDate('DD.MM.YYYY')}</div>
          </div>
          {item.Filial_Name && (
            <div className={Classes.join([styles.value, 'flex j-between mb-15'])}>
              <span>{t('PAWN_FILIAL_NAME')} :</span>
              <div>{item.Filial_Name}</div>
            </div>
          )}
          <div className={Classes.join([styles.value, 'flex j-between mb-15'])}>
            <span>
              {t('PAWN_NEXTPAYMENT_AMOUNT')} {item.Date_TA.formatDate('DD.MM.YYYY')}:
            </span>
            <div>{Number(item.Payment_TA).format()} ₸</div>
          </div>
        </div>
      </div>
      <div className="flex j-center mt-3">
        {paymentStore.canPay && <Button text="Оплатить" onClick={makePayment} />}
      </div>
    </div>
  );
};

export default withTranslation()(PaymentCard);
