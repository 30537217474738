import React, { useState, useEffect } from 'react';
import { Menu, Button, Container } from '../../../../components';
import MediaQuery from "react-responsive";
import { Classes } from '../../../../utils';
import styles from './Header.module.scss';
import { withTranslation, useTranslation } from "react-i18next/hooks";
import { Link, useLocation } from "react-router-dom";

import call from './assets/svg/call-black.svg';
import world from './assets/svg/world-black.svg';
import logo from './assets/svg/logo-primary.svg';
import burger from './assets/svg/bars-solid.svg';
import closeBurger from './assets/svg/times.svg';

import store from "../../../../services/store";
import { observer } from "mobx-react";
import publicAPI from "../../../../services/publicApi";
import API from "../../../../services/api";
import { toJS } from "mobx";
import avatar from './assets/svg/user.svg'

// const primaryMenu = [
//     {text: 'Услуги', to: '/services', id: 1},
//     {text: 'О нас', to: '/about', id: 2},
//     {text: 'Новости', to: '/news', id: 3},
//     {text: 'Вакансии', to: '/vacancies', id: 4},
//     {text: 'Контакты', to: '/contacts', id: 5},
// ];


const Header = observer((props) => {
        const [show, setShow] = useState(false);
        const location = useLocation();
        const isCabinet = location.pathname.includes('/cabinet');
        const [primaryMenu, setPrimaryMenu] = useState([]);
        const [secondaryMenu, setSecondaryMenu] = useState([]);
        const [phone, setPhone] = useState('');
        const {t} = props;
    const authorizeMenu = [
        {text: t('GENERAL'), to: '/', id: 1},
        // {text: 'Обратная связь', to: '/cabinet/feedback', id: 2},
        // {text: 'Калькулятор', to: '/cabinet/calculator', id: 3},
    ]
        const [authMenu, setAuthMenu] = useState([
            {text: 'Платежи', path: '/cabinet', id: 2},
            {text: 'История платежей', path: '/cabinet/history', id: 3},
            {text: t('NOTIFICATIONS'), path: '/cabinet/notifications', id: 4},
            {text: 'Калькулятор', path: '/cabinet/calculator', id: 5},
            {text: t('Feedbacks'), path: '/cabinet/feedback', id: 6},
            {text: t('CHANGE_PASSWORD'), path: '/cabinet/resetPassword', id: 7},
            {text: t('CHANGE_LANGUAGE'), path: '/cabinet/changeLanguage', id: 8},
        ])
        const _bootstrapAsync = async () => {
            try {
                let {language, user, refresh_token} = localStorage;
                user = JSON.parse(user || '{}');

                store.init({
                    language,
                    refresh_token,
                    user,
                });

            } catch (error) {

            } finally {
            }
        };
        useEffect(() => {
            _bootstrapAsync();
            loadPhone().then(res => setPhone(res))
        }, [])


        useEffect(() => {
            loadMenu().then(res => setPrimaryMenu(res));
            loadSubMenu().then(res => setSecondaryMenu(res));
            setShow(false);
        }, [location])

        useEffect(() => {
            loadMenu().then(res => setPrimaryMenu(res));
            loadSubMenu().then(res => setSecondaryMenu(res));
            setAuthMenu([
                {text: t('PAYMENTS'), path: '/cabinet', id: 2},
                {text: 'История платежей', path: '/cabinet/history', id: 3},
                {text: t('NOTIFICATIONS'), path: '/cabinet/notifications', id: 4},
                {text: t('Calculators'), path: '/cabinet/calculator', id: 5},
                {text: t('FEEDBACKS'), path: '/cabinet/feedback', id: 6},
                {text: t('CHANGE_PASSWORD'), path: '/cabinet/resetPassword', id: 7},
                {text: t('CHANGE_LANGUAGE'), path: '/cabinet/changeLanguage', id: 8},
            ]);
        }, [store.language])


        const loadMenu = async () => {
            const {data} = await publicAPI.get('/navigation/render/1');
            return data;
        }
        const loadSubMenu = async () => {
            try {
                store.toggleLoading(true);

                const {data} = await publicAPI.get('/navigation/render/2');
                await Promise.all(data.map(async (item) => {
                    const {data} = await publicAPI.get(`/services/${ item.service }`);
                    item.service_slug = data && data.slug;
                    item.path = `${ item.path }#${ item.service_slug }`
                    return item;
                }))

                return data;
            } catch (error) {
                console.log(error);
            } finally {
                store.toggleLoading(false);
            }
        }

        const loadPhone = async () => {
            const {data: {phone}} = await publicAPI.get('/phone');
            return phone;
        }
        return (
            <Container className={ styles.component }>
                <MediaQuery minWidth={ 1140 }>
                    <div className={ `flex a-center j-between test ${ isCabinet ? '' : 'mb-35' }` }>
                        { isCabinet ?
                            <div className={ Classes.join([styles.cabMenu, "mr-5"]) }>
                                <Menu list={ authorizeMenu }/>
                            </div>
                            :
                            <div className="fluid mr-5">
                                <Menu list={ primaryMenu }/>
                            </div>
                        }
                        { isCabinet && <Link to="/" className="flex">
                            <img className={ styles.logoCab } src={ logo } alt="logo-primary"/>
                        </Link> }
                        <div className="flex a-center ml-5">
                            <ContactPhone phone={ phone }/>
                            <div className="ml-3">
                                <Language/>
                            </div>
                            { !store.refresh_token &&
                            <React.Fragment>
                                <div className="ml-2">
                                    <Link to='/cabinet/login'>
                                        <Button text={ t('ENTER') }/>
                                    </Link>
                                </div>
                                <div className="ml-2">
                                    <Link to='/pawns'>
                                        <Button text={t('FindPawnsBtn')}/>
                                    </Link>
                                </div>
                                {/*<div className="ml-15">*/ }
                                {/*    <Button text="Регистрация"/>*/ }
                                {/*</div>*/ }
                            </React.Fragment> }
                            { store.refresh_token &&
                            <div className="ml-2">
                                <Link to='/cabinet'>
                                    <Button text="Профиль"/>
                                </Link>
                            </div> }

                        </div>
                    </div>
                    { !isCabinet &&
                    <div className="flex a-center j-center mb-45">
                        <Link to="/" className="flex">
                            <img src={ logo } alt="logo-primary"/>
                        </Link>
                    </div>
                    }
                    <div className="flex a-center j-center">
                        { !isCabinet && <Menu list={ secondaryMenu }/> }
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={ 1140 }>
                    <div className="flex j-between">
                        <div className="flex a-center j-center mb-15">
                            <Link to="/" className="flex">
                                <img
                                    className={ styles.logo }
                                    src={ logo } alt="logo-primary"/>
                            </Link>
                        </div>
                        <div className="flex a-center j-center mb-15">
                            <div className="flex a-center ml-5 j-between">
                                <MediaQuery minWidth={ 768 }>
                                    <div className="flex column mr-35 j-end ">
                                        <div className="flex j-between mb-25">
                                            { !store.refresh_token &&
                                            <>
                                                <Link to='/cabinet/login'>
                                                    <Button text={ t('ENTER') }/>
                                                </Link>
                                                <Link to='/pawns'>
                                                    <Button text={t('FindPawnsBtn')}/>
                                                </Link>
                                            </>
                                            }
                                            { store.refresh_token &&
                                            <div className="ml-2">
                                                <Link to='/cabinet'>
                                                    <Button text="Профиль"/>
                                                </Link>
                                            </div> }
                                        </div>
                                        <ContactPhone phone={ phone }/>
                                    </div>
                                </MediaQuery>
                                <img src={ burger }
                                     alt="menu"
                                     className={ styles.burger }
                                     onClick={ () => setShow((s) => !s) }/>
                            </div>
                        </div>
                    </div>
                    { show ? <MobileMenu secondaryMenu={ secondaryMenu }
                                         authMenu={ authMenu } primaryMenu={ primaryMenu } phone={ phone }
                                         setShow={ setShow }
                                         isCabinet={ isCabinet } t={ t }/> : '' }
                </MediaQuery>
            </Container>
        );
    }
)

const ContactPhone = ({phone}) => (
    <div className={ Classes.join([styles.phone, 'flex a-center']) }>
        <img src={ call } alt="call-black"/>
        <a href={ `tel:${ phone }` }>
            <span>{ phone }</span>
        </a>
    </div>
);

// TODO: Повесить обработчики событий, для смены языка
const Language = observer(() => {
    const [lang, setLang] = useState('ru');

    const change = () => {
        store.changeLang(lang);
        setLang(store.language);
    }

    useEffect(() => {

    }, [lang])

    return (
        <div className={ Classes.join([styles.language, 'flex a-center']) }
             onClick={ change }
        >
            <img src={ world } alt="world-black"/>
            <div>{ store.language }</div>
        </div>
    )
});


const MobileMenu = observer(({setShow, isCabinet, phone, primaryMenu, secondaryMenu, authMenu, t}) => {
        const onPress = (e) => {
            e.preventDefault();
            store.resetAuth();
            history.push('/');
        }
        return (
            <div className={ Classes.join([styles.mobileMenu, 'pl-15 pt-15 pb-15 pr-15']) }>
                <div className={ Classes.join([styles.mm_top, 'pl-15 pt-15 pb-15 pr-15']) }>
                    <div className="flex j-between mb-15">
                        <img
                            className={ styles.closeBtn }
                            src={ closeBurger } alt="Закрыть меню" onClick={ () => setShow(false) }/>
                        <ContactPhone phone={ phone }/>
                    </div>
                </div>
                <div className="flex column">
                    { !isCabinet && <Menu list={ secondaryMenu }/> }
                    { isCabinet &&
                    <div className={ Classes.join([styles.user, "flex column j-center a-center mb-2"]) }>
                        <img className="mb-2" src={ avatar } alt="Аватар пользователя"/>
                        <p>{ store.user.fullname }</p>
                    </div>
                    }
                    { isCabinet && <Menu list={ authMenu }/> }
                    <div className={ Classes.join([styles.divider, 'divider mt-15 mb-15']) }></div>
                    { primaryMenu && <Menu list={ primaryMenu }/> }
                </div>

                <MediaQuery maxWidth={ 768 }>
                    <div className="flex column a-center ">
                        <div className="flex j-center mb-15">
                            { !store.refresh_token &&
                            <>
                                <Link to='/cabinet/login'>
                                    <Button text={ t('ENTER') }/>
                                </Link>
                                <Link to='/pawns'>
                                    <Button text={t('FindPawnsBtn')}/>
                                </Link>
                            </>
                            }
                            { store.refresh_token &&
                            <div className="flex">
                                <Link to='/cabinet'>
                                    <Button theme='secondary' text="Профиль"/>
                                </Link>
                                <Link to="/" onClick={ onPress }>
                                    <Button text={ t('LOGOUT') }/>
                                </Link>
                            </div> }

                        </div>
                        <Language/>
                    </div>
                </MediaQuery>
            </div>
        )
    }
)
export default withTranslation()(Header);
