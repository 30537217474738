import React from 'react';
import {Container, Button, Form, Input} from '../../../../components';
import {Classes} from '../../../../utils';
import styles from './ContactForm.module.scss';
import MediaQuery from "react-responsive";
import gmail from './assets/svg/gmail-background.svg';
import {action, computed, makeObservable, observable, toJS} from "mobx";
import store from "../../../../services/store";
import API from "../../../../services/api";
import {observer} from "mobx-react";
import PhoneInput from "../../../../components/PhoneInput/PhoneInput";
import {withTranslation} from "react-i18next/hooks";
import {toast} from "react-toastify";

const TELEGRAM_TOKEN = '1682418127:AAEQCfaHF8mpUb9-PZUy9Tk0dKxVUMvDjRA';
const TELEGRAM_CHAT_ID = 275467043;

class ContactStore {
    form = {
        name: '',
        phone: '',
        email: ''
    }
    redirect = false;

    constructor() {
        makeObservable(this, {
            redirect: observable,
            form: observable,
            onChange: action,
            onChangePassword: action,
            onPress: action,
            formInvalid: computed
        })
    }

    onChange = (field, value) => {
        this.form[field] = value;
    };
    onChangePassword = (field, value) => {
        this.form[field] = value;
    };
    onPress = async () => {
        try {
            const TELEGRAM_TEXT = encodeURI("<b>Новая заявка на SuperLombard</b>\n\n" +
                `<b>Имя:</b> ${this.form.name}\n`+
                        `<b>Email:</b> ${this.form.email}\n`+
                        `<b>Телефон:</b> +${this.form.phone}`);

            store.toggleLoading(true)
            const q = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage?chat_id=${TELEGRAM_CHAT_ID}&text=${TELEGRAM_TEXT}&parse_mode=HTML`;
            console.log(q);
            const test = await fetch(q)
                .then(res => {
                if (res.status === 200) {
                    toast('Заявка успешно отправлена!', {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.SUCCESS,
                        autoClose: 8000,
                    });
                }
            })
                .catch(err => console.error(err))
                .finally(()=>{
                    this.onChange('email','')
                    this.onChange('name','')
                    this.onChange('phone','')

                })

        } catch (error) {
        } finally {
            store.toggleLoading(false);

        }
    };
    regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    get formInvalid() {
        return this.form.phone.length === 16 &&
            this.form.name.length !== 0 &&
            (this.form.email.length > 0 &&
                this.form.email.length < 256 &&
                this.regEx.test(this.form.email) === true)
    }
}


const contactStore = new ContactStore();

const ContactForm = observer(({t}) => {

        // TODO: Валидация формы
        const submitHandler = () => {
        };

        return (
            <Container className={styles.component}>
                <div className="flex j-center">
                    <h2>{t('FEEDBACKS')}</h2>
                </div>

                <div className={Classes.join([styles.container, 'flex j-between'])}>
                    <div className={Classes.join([styles.form, 'mt-7'])}>
                        <Form theme="primary">
                            <Input onInput={contactStore.onChange} type="text" name="name" placeholder={ t('NAME_SURNAME') }
                                   value={contactStore.form.name}/>
                            <Input onInput={contactStore.onChange} type="email" name="email" placeholder="E-mail"
                                   value={contactStore.form.email}
                            />
                            <PhoneInput onChange={contactStore.onChange} type="text" name="phone"
                                        placeholder={t('PHONE')}
                                        value={contactStore.form.phone}
                            />
                            <Button disabled={!contactStore.formInvalid} onClick={contactStore.onPress}
                                    text={t('SUBMIT_REQUEST')}
                                    type="submit" size="middle"/>
                        </Form>
                    </div>
                    <MediaQuery minWidth={540}>
                        <div className={styles.image}>
                            <img src={gmail} alt="gmail-background"/>
                        </div>
                    </MediaQuery>
                </div>
            </Container>
        );
    }
);

export default withTranslation()(ContactForm);
