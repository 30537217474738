import React, { useState, useEffect } from 'react';
import { Container } from '../../../../components';
import { Classes } from '../../../../utils';
import styles from './Footer.module.scss';

import googlePlay from './assets/images/google-play-badge.png';
import appStore from './assets/images/app-store-badge.png';

import facebook from './assets/svg/facebook-white.svg';
import twitter from './assets/svg/twitter-white.svg';
import linkedin from './assets/svg/linkedin-white.svg';
import pininterest from './assets/svg/pininterest-white.svg';
// import {Link} from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import store from "../../../../services/store";
import publicAPI from "../../../../services/publicApi";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next/hooks";

const contacts = [
        { text : 'E-Mail:', email : 'info@superlombard.kz' },
        { text : 'Единый номер:', phones : ['8 (7162) 92-57-57', '+77002925757'] },
        { text : 'Головной офис: г. Кокшетау, ул. Пушкина 14' },
        { text : 'Автоломбард, г. Кокшетау:', phones : ['+7 702 921 48 61'] },
        { text : 'Автоломбард, г. Нур-Султан:', phones : ['+7 778 001 72 52'] },
        { text : 'Автоломбард, г. Алматы:', phones : ['+7 707 544 7657'] },
    ],
    // services = [
    //     {text: 'Автоломбард', to: '/'},
    //     {text: 'Скупка золота', to: '/'},
    //     {text: 'Скупка шуб', to: '/'},
    // ],
    links = [
        { text : 'Как проверить золото у нас', to : '/' },
        { text : 'Наши условия', to : '/' },
        { text : 'Как погасить долг раньше', to : '/' },
    ],
    refs = [
        { text : 'О сайте', to : '/about' },
        { text : 'Правила и условия', to : '/terms' },
        { text : 'Политика конфиденциальности', to : '/terms' },
        { text : 'Блог', to : '/news' },
    ],
    socials = [
        { image : facebook, alt : 'facebook-white', to : '/' },
        { image : twitter, alt : 'twitter-white', to : '/' },
        { image : linkedin, alt : 'linkedin-white', to : '/' },
        { image : pininterest, alt : 'pininterest-white', to : '/' },
    ];

const Footer = observer((props) => {
    const [services, setServices] = useState([]);
    const [footMenu, setFootMenu] = useState([]);
    const [footer, setFooter] = useState([]);

    useEffect(() => {
        loadSubMenu().then(res => setServices(res));
        loadMenu().then(res => setFootMenu(res));
        loadFooter().then(res => setFooter(res));
    }, [store.language]);

    const loadMenu = async () => {
        const { data } = await publicAPI.get('/navigation/render/3');
        return data;
    }

    const loadSubMenu = async () => {
        try {
            store.toggleLoading(true);
            const { data } = await publicAPI.get('/navigation/render/2');
            await Promise.all(data.map(async (item) => {
                const { data } = await publicAPI.get(`/services/${ item.service }`);
                item.service_slug = data && data.slug;
                item.path = `${ item.path }#${ item.service_slug }`
                return item;
            }))

            return data;
        } catch (error) {
            console.log(error);
        } finally {
            store.toggleLoading(false);
        }
    }

    const loadFooter = async () => {
        const { data } = await publicAPI.get('/footer');
        return data;
    }
    return (
        <Container className={ styles.component }>
            <div className={ styles.container }>
                <Menu t={ props.t } contacts={ footer } services={ services }/>
                <Meta title={ footer.mobile_title }
                      text={ footer.mobile_text }
                      link_android={ footer.link_android }
                      link_iphone={ footer.link_iphone }
                      support={ footer }
                />
                <Contacts refs={ footMenu } socials={ footer.social_link_list }/>
            </div>
        </Container>
    )
});

const Menu = observer(({ contacts, services, t }) => {

    return (
        <div className={ Classes.join([styles.menu, 'flex']) }>
            <div className={ Classes.join([styles.addresses, 'flex column']) }>
                <div className={ styles.title }>{ contacts &&
                contacts.title_contacts &&
                (contacts.title_contacts[store.language] ?
                    contacts.title_contacts[store.language] :
                    contacts.title_contacts.ru) }
                </div>
                <ul className={ styles.list }>
                    { contacts.list && contacts.list.map(({ title, text, type }, index) => (
                        <li className={ styles.item } key={ index }>
                            { title && (title[store.language] ? title[store.language] : title.ru) }

                            { type === 'email' ? <a href={ `mailto:${ text.ru }` }>
                                    { text && (text[store.language] ? text[store.language] : text.ru) }
                                </a> :
                                type === 'phone'
                                    ?
                                    <a href={ `tel:${ text.ru }` }>
                                        { text && (text[store.language] ? text[store.language] : text.ru) }
                                    </a>
                                    :
                                    <span>{ text && (text[store.language] ? text[store.language] : text.ru) }</span>
                            }
                        </li>
                    )) }
                </ul>
            </div>
            <div className={ Classes.join([styles.services, 'flex column']) }>
                <div className={ styles.title }>{ t('SERVICES') }</div>
                <ul className={ Classes.join([styles.list, 'flex wrap']) }>
                    { services &&
                    services.map(({ id, text, path = '' }) => (
                        <li className={ styles.item } key={ id }>
                            <Link smooth to={ path }
                                  title={ text && (text[store.language] ? text[store.language] : text.ru) }>
                                { text && (text[store.language] ? text[store.language] : text.ru) }
                            </Link>
                        </li>
                    )) }
                </ul>
            </div>
            {/*<div className={Classes.join([styles.links, 'flex column'])}>*/ }
            {/*    <div className={styles.title}>Полезные ссылки</div>*/ }
            {/*    <ul className={styles.list}>*/ }
            {/*        {links.map(({text, to}, index) => (*/ }
            {/*            <li className={styles.item} key={index}>*/ }
            {/*                <Link to={to}>{text}</Link>*/ }
            {/*            </li>*/ }
            {/*        ))}*/ }
            {/*    </ul>*/ }
            {/*</div>*/ }
        </div>
    )
});

const Meta = observer(({ support, title, text, link_iphone, link_android }) => (
    <div className={ Classes.join([styles.meta, 'flex j-between']) }>
        <div className={ styles.app }>
            <div className={ styles.title }>
                { title && (title[store.language] ? title[store.language] : title.ru) }
            </div>
            <div className={ styles.text }>
                <p>
                    { text && (text[store.language] ? text[store.language] : text.ru) }
                </p>
            </div>
            <div className={ Classes.join([styles.badges, 'flex']) }>
                <div className={ styles.badge }>
                    <a href={ link_android }>
                        <img src={ googlePlay } alt="google-play-badge"/>
                    </a>
                </div>
                <div className={ styles.badge }>
                    <a href={ link_iphone }>
                        <img src={ appStore } alt="app-store-badge"/>
                    </a>
                </div>
            </div>
        </div>
        <div className={ styles.service }>
            <div className={ styles.title }>
                { support.title_support && (support.title_support[store.language] ? support.title_support[store.language] : support.title_support.ru) }
            </div>
            <ul className={ styles.list }>
                { support.support_list && support.support_list.map(({ title, text, type }, index) => (
                    <li className={ styles.item } key={ index }>
                        { title && (title[store.language] ? title[store.language] : title.ru) }

                        { type === 'email' ? <a href={ `mailto:${ text.ru }` }>
                                { text && (text[store.language] ? text[store.language] : text.ru) }
                            </a> :
                            type === 'phone'
                                ?
                                <a href={ `tel:${ text.ru }` }>
                                    { text && (text[store.language] ? text[store.language] : text.ru) }
                                </a>
                                :
                                <span>{ text && (text[store.language] ? text[store.language] : text.ru) }</span>
                        }
                    </li>
                )) }
            </ul>
        </div>
    </div>
))

const Contacts = observer(({ refs, socials }) => (
        <div className={ Classes.join([styles.contacts, 'flex a-center j-between']) }>
            <div className={ styles.refs }>
                <ul className={ Classes.join([styles.list, 'flex']) }>
                    { refs && refs.map(({ id, text, path = '' }, index) => (
                        <li className={ styles.item } key={ index }>
                            <Link to={ `${ path }#top` }>
                                { text && (text[store.language] ? text[store.language] : text.ru) }
                            </Link>
                        </li>
                    )) }
                </ul>
            </div>
            <div className={ styles.links }>
                <ul className={ Classes.join([styles.list, 'flex']) }>
                    { socials && socials.map(({ icon, title, link }, index) => (
                        <li className={ styles.item } key={ index }>
                            <a href={ link }>
                                <img
                                    src={ `${ process.env.REACT_APP_IMG_URL }${ icon ? icon.url : '' }` }
                                    alt={ title }/>
                            </a>
                        </li>
                    )) }
                </ul>
            </div>
        </div>
    )
)

export default withTranslation()(Footer);
