import React, {Component} from 'react';
import styles from './History.module.scss';
import {HistoryItem} from "../index";
import {observer} from "mobx-react";
import {observable, action} from 'mobx';
import store from "../../../../../services/store";
import PaymentsStore from "../../../../../stores/PaymentsStore";
import {Classes} from "../../../../../utils";
import FlatList from 'flatlist-react';

@observer
class History extends Component {
    list = React.createRef();

    // @action
    // _scrollTop = () => {
    //     this.scrollButton = false;
    //     this.list.current.scrollToIndex({index: 0, animated: true});
    // };
    // @action
    // _onRefresh = () => {
    //     if (!this.props.listStore.refreshing) {
    //         this.props.listStore.setRefreshing(true);
    //         this.props.listStore.getItems(1);
    //     }
    // };
    //
    // @action
    // _onEndReached = () => {
    //     if (
    //         !this.props.listStore.loading &&
    //         this.props.listStore.query.page < this.props.listStore.totalPages
    //     ) {
    //         this.props.listStore.setLoading(true);
    //         this.props.listStore.getItems(this.props.listStore.query.page + 1);
    //     }
    // };

    // @action
    // onMomentumScrollBegin = event => {
    //     this.scrollButton = event.nativeEvent.contentOffset.y > 70;
    // };

    componentDidMount() {
        store.toggleLoading(true);
        this.getItems();
    }

    getItems = async () => {
        try {
            // const data = await this.props.listStore.getItems();
            const data = await PaymentsStore.getItems();
            // this.props.onGetItems && this.props.onGetItems(data);
        } catch (error) {
        }
    };

    renderItem = ({item}, idx) => {
        return (
            <div key={idx}>
                <HistoryItem key={item._id} item={item}/>
            </div>
        )
    }

    render() {
        return (
            <div className={Classes.join([styles.component, "flex column a-center j-start"])}>
                <h2>История платежей</h2>
                {/*<FlatList list={} />*/}
                {PaymentsStore.items.map((item, idx) => {
                        return (
                                <HistoryItem key={item._id} item={item}/>
                        )
                    }
                )}
            </div>


        )
    }
}

export default History;
