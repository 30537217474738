import React from 'react';
import { CreditСalculator } from '../utils/components';
import { Vacancies as VacanciesForm } from './components';

const Vacancies = () => {
  return (
    <>
      <CreditСalculator />
      <VacanciesForm />
    </>
  );
};

export default Vacancies;
