import React, {Component} from 'react';
import { Home, Services, About, Vacancies, Contacts, News, Pawns } from './views';
import {HashRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {ToastContainer} from "react-toastify";

import {Header, Footer} from './views/utils/components';
import {Login, Profile, Calculator} from "./views/cabinet";

import store from "./services/store";
import FeedbackForm from "./views/cabinet/Feedback/FeedbackForm";
import {configure} from "mobx";
import FeedbackList from "./views/cabinet/FeedbackList/FeedbackList";
import 'react-toastify/dist/ReactToastify.css';
import {observer} from "mobx-react";
import Conditions from "./views/Conditions/Conditions";
import ForgetPassword from "./views/cabinet/ForgetPassword/ForgetPassword";

configure({
    enforceActions: "always",
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    observableRequiresReaction: true,
    disableErrorBoundaries: true
})

/**
 * @format
 * @flow
 */

@observer
class App extends Component{
    componentDidMount(){
        this._bootstrapAsync();
    }
     _bootstrapAsync = async () => {
        try {
            let {language, user, refresh_token} = localStorage;
            user = JSON.parse(user || '{}');

            store.init({
                language,
                refresh_token,
                user,
            });

        } catch (error) {

        } finally {
        }
    };

    render() {
        return (
            <>
                <Router>
                    <Header/>
                    <ToastContainer/>
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/services" component={Services}/>
                        <Route path="/pawns" component={Pawns}/>
                        <Route path="/about" component={About}/>
                        <Route path="/vacancies" component={Vacancies}/>
                        <Route path="/contacts" component={Contacts}/>
                        <Route path="/news" component={News}/>
                        <Route path="/terms" component={Conditions}/>
                        <Route path="/cabinet" component={Profile} exact/>
                        <Route path="/cabinet/payments" component={Profile}/>
                        <Route path="/cabinet/history" component={Profile}/>
                        <Route path="/cabinet/notifications" component={Profile}/>
                        <Route path="/cabinet/resetPassword" component={Profile}/>
                        <Route path="/cabinet/changeLanguage" component={Profile}/>
                        <Route path="/cabinet/forgetPassword" component={ForgetPassword}/>
                        <Route path="/cabinet/login" component={Login}/>
                        <Route path="/cabinet/calculator" component={Profile}/>
                        <Route path="/cabinet/feedback" component={Profile}/>
                        <Route path="/cabinet/feedbackList" component={Profile}/>
                        <Redirect to="/"/>
                    </Switch>
                    <Footer/>
                </Router>
            </>
        );
    }
};

export default (App);
