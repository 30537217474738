// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9Xv2JtUHk8vml2Jf-so6Zw\\=\\={padding:0}._9Xv2JtUHk8vml2Jf-so6Zw\\=\\= img{display:flex;max-width:100%}", "",{"version":3,"sources":["webpack://./src/views/utils/components/Banner/Banner.module.scss"],"names":[],"mappings":"AAAA,6BACG,SAAA,CAEA,iCACG,YAAA,CACA,cAAA","sourcesContent":[".component {\r\n   padding: 0;\r\n\r\n   img {\r\n      display: flex;\r\n      max-width: 100%;\r\n   }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "_9Xv2JtUHk8vml2Jf-so6Zw=="
};
export default ___CSS_LOADER_EXPORT___;
