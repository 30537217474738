import React from 'react';
import styles from './UserPanel.module.scss';
import {Classes} from "../../../../../utils";

import avatar from './assets/svg/user.svg';
import arrow from './assets/svg/arrow.svg'

import store from '../../../../../services/store';
import {Link, NavLink, useHistory} from "react-router-dom";
import {withTranslation} from "react-i18next/hooks";


const UserPanel = (props) => {
    const history = useHistory();
    const onPress = (e) => {
        e.preventDefault();
        store.resetAuth();
        history.push('/');
    }
    const {t} = props;
    const buttons = {
        '/cabinet': 'Платежи',
        '/cabinet/history': 'История платежей',
        '/cabinet/notifications': t('NOTIFICATIONS'),
        '/cabinet/calculator': 'Калькулятор',
        '/cabinet/feedback': 'Обратная связь',
        '/cabinet/resetPassword': t('CHANGE_PASSWORD'),
        '/cabinet/changeLanguage': t('CHANGE_LANGUAGE'),
    }

    return (

        <div className={Classes.join([styles.component, 'pt-4 pr-2 pl-2 pb-5 mr-3'])}>
            <div className="flex column j-center a-center">
                <img className="mb-2" src={avatar} alt="Аватар пользователя"/>
                <p>{store.user.fullname}</p>
            </div>
            <div className="mt-4 mb-4">

                {buttons && Object.entries(buttons).map(([link, title], index) => (
                        <NavLink key={index} to={link} exact={true} activeClassName={styles.active}
                                 className="flex a-center j-between mb-2">{title} <img src={arrow}
                                                                                       alt="Указатель"/></NavLink>
                    )
                )
                }

            </div>
            <Link to="/" onClick={onPress}>{t('LOGOUT')}</Link>
        </div>
    )

}

export default withTranslation()(UserPanel);