import React, {Component} from 'react';
import {Button, Form, Input} from "../../../../../components";
import styles from './ResetPassword.module.scss';
import {observer} from "mobx-react";
import {Classes} from "../../../../../utils";
import Spinner from "../../../../../components/Spinner";
import {makeObservable, observable, action, computed} from "mobx";
import store from "../../../../../services/store";
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next/hooks";
import API from "../../../../../services/api";
import {toast} from "react-toastify";


class ResetStore{
    constructor(){
        makeObservable(this,{
            form:observable,
            onChange:action,
            isEmailValid:computed,
            isEqualPasswords:computed,
            isFormValid:computed,
        })
    }
    form = {email: '', new_password: '', repeat_password: ''};

    onChange = (name, value) => {
        this.form[name] = value;
    };

    get isEmailValid() {
        const regexp = new RegExp(
            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/gi,
        );
        return regexp.test(this.form.email.trim());
    }

    isPasswordValid(password, length = 6) {
        const passRegExp = new RegExp(
            `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{${length},})`,
        );
        return passRegExp.test(password);
    }

    get isEqualPasswords() {
        return (
            this.form.new_password.length > 0 &&
            this.form.new_password === this.form.repeat_password
        );
    }

    get isFormValid() {
        return (
            this.isPasswordValid(this.form.new_password) && this.isEqualPasswords
        );
    }
}

@observer
class ResetPassword extends Component {
    resetStore = new ResetStore();

    onPress = async () => {
        try {
            store.toggleLoading(true);
            const {data} = await API.post('users/password/set', {
                password: this.resetStore.form.new_password,
            });
            toast(data.message, {
                position: toast.POSITION.TOP_CENTER,
                type: toast.TYPE.SUCCESS,
                autoClose:5000,
            })
            store.resetAuth();

            this.props.history.push('/cabinet/login');

        } catch (error) {
            console.log(error);
        } finally {
            store.toggleLoading(false);
        }
    };



    render() {
        const {t, i18n} = this.props;
        return (
            <div className={Classes.join([styles.component, "flex column a-center j-start"])}>
                <h3 className='mb-4'>{t('EDIT_PROFILE_NOTE')}</h3>
                <Form>
                    <Input value={this.resetStore.form.new_password}
                           onInput={this.resetStore.onChange}
                           name='new_password'
                           type='password'
                           placeholder={t('NEW_PASSWORD')}
                           invalid={!this.resetStore.isPasswordValid(this.resetStore.form.new_password)}
                           errorMessage={this.resetStore.isPasswordValid(this.resetStore.form.new_password) ||t('PASSWORD_NOTE')}
                    />
                    <Input value={this.resetStore.form.repeat_password}
                           type='password'
                           name='repeat_password'
                           onInput={this.resetStore.onChange}
                           placeholder={t('REPEAT_PASSWORD')}
                           invalid={!this.resetStore.isEqualPasswords}
                           errorMessage={this.resetStore.isEqualPasswords ||t('PASSWORDS_NOT_EQUAL')}
                    />
                    <Button
                        text={t('SEND')}
                        theme='secondary'
                        size='middle'
                        disabled={!this.resetStore.isFormValid}
                        onClick={this.onPress}
                    />
                </Form>
            </div>


        )
    }
}

export default withTranslation()(withRouter(ResetPassword));
