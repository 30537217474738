import React from 'react';
import styles from './HistoryItem.module.scss';
import {Classes} from "../../../../../utils";

const HistoryItem = ({item})=>{
    return(
        <div className={Classes.join([styles.component, 'flex j-between a-center pt-2 pr-2 pl-2 pb-15 mt-2'])}>
            <div className={styles.info}>
                <p className={Classes.join([styles.date, 'mb-2'])}>  {item.status_date.formatDate('DD.MM.YYYY, HH:mm')}</p>
                <p className="flex column">
                    <span className="mb-1">Номер залога: {item.pawn.pawn_id}</span>
                    <span>Номер платежа: {item.orderId}</span>
                </p>
            </div>
            <p className={styles.value}>{item.amount} ₸</p>
        </div>
    )
}

export default HistoryItem;