import axios from "axios";
import store from './store';
import {toast} from "react-toastify";

// const baseURL = 'http://localhost:1337';
const baseURL = 'https://strapi.superlombard.kz';
const publicAPI = axios.create({baseURL});

let isRefreshing = false;

publicAPI.interceptors.response.use(
    async function (response) {
        // console.log('API:', response);
        if (response.status === 200 && response.data.message) {
            console.log({
                title: response.data.message,
            });
        }
        if (response.data.token) {
            store.setTokens(response.data);
        }
        if (response.data.user && response.data.user.id !== undefined) {
            try {
                store.setUser(response.data.user);
            } catch (error) {
            }
        }

        return response;
    },
    async function (error) {
        console.log('API ERROR:', error.response);
        if (error.response && error.response.status === 400) {
            toast(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                type: toast.TYPE.ERROR,
                autoClose:8000,
            })
            console.error({
                title: error.response.data.message,
            });
        } else if (error.response && error.response.status === 401) {
            if (!isRefreshing) {
                try {
                    await API.refreshToken();
                    error.config.headers = {
                        Authorization: `Bearer ${store.token}`,
                    };
                    return API(error.config);
                } catch (refreshError) {
                    console.log(refreshError);
                } finally {
                }
            }
        }
        const err = error.toJSON();
        if (err.message === 'Network Error') {
            // RNNotificationBanner.Warn({
            //   title: 'Проверьте соединение с интернетом',
            // });
        }

        return Promise.reject(error);
    },
);


export default publicAPI;
