import React from 'react';
import { CreditСalculator } from '../utils/components';
import { About as AboutUs, WhyUs } from './components';

const Services = () => {
  return (
    <>
      <CreditСalculator />
      <AboutUs />
      <WhyUs />
    </>
  );
};

export default Services;
