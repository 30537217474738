import { makeObservable, observable, computed, action, runInAction, toJS } from 'mobx';
import API from '../services/api';
import { debounce } from 'debounce';
import store from '../services/store';
import { toast } from 'react-toastify';

class BaseListStore {
  baseUrl = '';
  listUrl = '';
  initialFilter = {};

  constructor(baseUrl, listUrl, initialFilter) {
    makeObservable(this, {
      query: observable,
      loading: observable,
      refreshing: observable,
      _items: observable,
      _item: observable,
      filter: observable,
      items: computed,
      item: computed,
      setQuery: action,
      updateInArray: action,
      update: action,
      addItem: action,
      removeItem: action,
      onChangeText: action,
      setLoading: action,
      setRefreshing: action,
      setFilter: action,
      applyFilter: action,
    });
    this.baseUrl = baseUrl;
    this.listUrl = listUrl;
    this.initialFilter = initialFilter;
    this.setQuery({ page: 1, limit: 100, text: '', ...initialFilter });
    this.setFilter(initialFilter);
  }
  query = {};

  setQuery = (query) => {
    this.query = query;
  };

  loading = true;
  refreshing = false;

  _items = [];

  get items() {
    return this._items.slice();
  }
  set items(v) {
    this._items = v;
  }
  _item = null;
  get item() {
    return this._item;
  }
  set item(v) {
    this._item = v;
  }

  async updateItem(item) {
    try {
      const { data } = await API.put(this.baseUrl, toJS(item));
      toast('Успешно изменено', {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.SUCCESS,
        autoClose: 8000,
      });
      this.updateInArray(data);
      return data;
    } catch (error) {
      console.log('UPDATE_ERROR', error);
      throw error;
    }
  }
  updateInArray = (data) => {
    let itemIndex = this._items.findIndex((x) => x._id === data._id);
    if (itemIndex !== -1) {
      this._items[itemIndex] = data;
    }
  };

  update = (data) => {
    let itemIndex = this._items.findIndex((x) => x._id === data._id);
    if (itemIndex !== -1) {
      this._items[itemIndex] = { ...this._items[itemIndex], ...data };
    }
    return this._items[itemIndex];
  };

  async addItem(item) {
    try {
      const { data } = await API.post(this.baseUrl, toJS(item));
      toast('Успешно создано', {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.SUCCESS,
        autoClose: 8000,
      });
      this.items = [data, ...this.items];
    } catch (error) {
      console.log('ADD_ERROR', error);
      throw error;
    }
  }

  async removeItem(id) {
    try {
      await API.delete(`${this.baseUrl}/${id}`);
      toast('Успешно удалено', {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.SUCCESS,
        autoClose: 8000,
      });
      this.items = this.items.filter((x) => x._id !== id);
    } catch (error) {
      console.log('REMOVE_ERROR', error);
      throw error;
    }
  }
  handleResult = (data) => {};

  getItems = async (page = 1) => {
    try {
      const { data } = await API.get(`${this.baseUrl}/${this.listUrl}`, {
        params: { ...toJS(this.query), page },
      });
      data.docs = data.docs || [];

      runInAction(() => {
        this.items = page === 1 ? data.docs : [...this.items, ...data.docs];
        this.query.page = page;
        this.totalPages = data.totalPages;
        this.handleResult(data);
      });

      return data;
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
      store.toggleLoading(false);
      this.setRefreshing(false);
    }
  };

  search = debounce(() => {
    this.setLoading(true);
    store.toggleLoading(true);
    this.getItems();
  }, 700);

  onChangeText = (name, text) => {
    this.query.text = text;
    this.search();
  };

  setLoading = (value) => {
    this.loading = value;
  };
  setRefreshing = (value) => {
    this.refreshing = value;
  };

  filter = {};
  setFilter = (filter) => {
    this.filter = { ...toJS(this.filter), ...filter };
  };

  applyFilter = (reset) => {
    if (reset) {
      this.filter = this.initialFilter;
    }
    this.query = { page: 1, limit: 100, ...toJS(this.filter) };
    this.setLoading(true);
    store.toggleLoading(true);
    this.getItems();
  };
}
export default BaseListStore;
