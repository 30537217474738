import React, {Component} from 'react';
import {Button, Form, Input} from "../../../../../components";
import styles from './ChangeLanguage.module.scss';
import {observer} from "mobx-react";
import {Classes} from "../../../../../utils";
import {makeObservable, observable, action, computed} from "mobx";
import store from "../../../../../services/store";
import {withTranslation} from "react-i18next/hooks";

import API from "../../../../../services/api";
import {toast} from "react-toastify";
import kk from './assets/images/kz.png';
import ru from './assets/images/ru.png';
import en from './assets/images/en.png';

const LANGUAGES = {
    kk,
    ru,
    en
};

@observer
class ChangeLanguage extends Component {

    changeLanguage = (language) => {
        // console.log(language);
        store.changeLanguage(language);
    };


    render() {
        const {t} = this.props;
        return (
            <div className={Classes.join([styles.component, "flex column a-center j-start"])}>

                <h2>{t('CHOOSE_LANGUAGE')}</h2>
                <Form>
                    {Object.entries(LANGUAGES).map(([l, icon]) => {
                        return (
                            <Button key={l} text={l} icon={icon} size='middle'
                                    theme={
                                        store.language === l && 'secondary'}

                                    onClick={() => this.changeLanguage(l)}
                            />
                        )
                    })}
                </Form>
            </div>


        )
    }
}

export default withTranslation()(ChangeLanguage);
