// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p0fiRUrTCikUKKMr7JgepA\\=\\= input{width:100%;padding:15px 20px;font-family:\"Lato\",sans-serif;color:#000;border:3px solid rgba(0,0,0,0);outline:none;border-radius:8px;transition:all .2s}.p0fiRUrTCikUKKMr7JgepA\\=\\= input::placeholder{color:rgba(0,0,0,.5)}.p0fiRUrTCikUKKMr7JgepA\\=\\=._4209REHzfeVqEGfofSBacw\\=\\= input{margin:0 0 5px;box-shadow:0 0 0 3px rgba(244,67,54,.4)}.p0fiRUrTCikUKKMr7JgepA\\=\\=._4209REHzfeVqEGfofSBacw\\=\\= small{font-weight:700;color:#f44336}", "",{"version":3,"sources":["webpack://./src/components/InputCustom/Input.module.scss"],"names":[],"mappings":"AACE,kCACE,UAAA,CACA,iBAAA,CACA,6BAAA,CACA,UAAA,CACA,8BAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CAEA,+CACE,oBAAA,CAKF,8DACE,cAAA,CACA,uCAAA,CAGF,8DACE,eAAA,CACA,aAAA","sourcesContent":[".component {\r\n  input {\r\n    width: 100%;\r\n    padding: 15px 20px;\r\n    font-family: 'Lato', sans-serif;\r\n    color: #000;\r\n    border: 3px solid transparent;\r\n    outline: none;\r\n    border-radius: 8px;\r\n    transition: all 0.2s;\r\n\r\n    &::placeholder {\r\n      color: rgba(#000, 0.5);\r\n    }\r\n  }\r\n\r\n  &.invalid {\r\n    input {\r\n      margin: 0 0 5px;\r\n      box-shadow: 0 0 0 3px rgba(#f44336, 0.4);\r\n    }\r\n\r\n    small {\r\n      font-weight: 700;\r\n      color: #f44336;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "p0fiRUrTCikUKKMr7JgepA==",
	"invalid": "_4209REHzfeVqEGfofSBacw=="
};
export default ___CSS_LOADER_EXPORT___;
