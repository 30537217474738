import React, {useEffect} from 'react';
import { Banner, CreditСalculator } from '../utils/components';
import { Services as OurServices } from './components';

const Services = (props) => {

  return (
    <>

      <Banner />
      <CreditСalculator />
      <OurServices />
    </>
  );
};

export default Services;
