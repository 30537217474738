import React, {Component} from 'react';
import styles from "./FeedbackForm.module.scss";
import store from "../../../services/store";
import {Link, Redirect, Route, useHistory} from 'react-router-dom';
import Classes from "../../../utils/classes";
import {Button, Form, Input} from "../../../components";
import {withTranslation} from "react-i18next/hooks";


import FeedBackImg from './assets/images/feedback.png'
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {observer} from "mobx-react";

import success from './assets/images/svg/success.svg';
import failed from './assets/images/svg/failed.svg';

import API from "../../../services/api";
import {toast} from "react-toastify";

class FeedbackStore {
    constructor() {
        makeObservable(this, {
            form: observable,
            onChange: action,
            formInvalid: computed,
            send: action,
        })
    }

    form = {
        title: '',
        question: '',
    };
    send = async () => {
        try {
            store.toggleLoading(true);
            const {status} = await API.post('feedbacks', this.form);

            if (status === 200) {
                toast('Заявка успешно отправлена!', {
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 8000,
                });
            }


            runInAction(() => {
                this.form.question = '';
                this.form.title = '';
            });
        } catch (error) {
            console.log(error);
        } finally {
            store.toggleLoading(false);
        }
    };

    onChange = (field, value) => {
        this.form[field] = value;
    };

    get formInvalid() {
        return this.form.question.length === 0 || this.form.title.length === 0;
    }


}

@observer
class FeedbackForm extends Component {
    feedbackStore = new FeedbackStore();
    state = {
        success: false,
        failed: false,
        redirect: false,
    }
    send = () => {
        try {
            this.feedbackStore.send();
            this.setSuccess(true);
        } catch (e) {
            console.log(e);
            this.setFailed(true);
        }

    }

    setSuccess = (status) => {
        this.setState({success: status})
    }
    setFailed = (status) => {
        this.setState({failed: status})
    }
    setRedirect = (status) => {
        this.setState({redirect: status})
    }

    render() {
        const {t} = this.props;
        if (this.state.redirect) {
            return <Redirect to="/cabinet/feedbackList"/>
        }
        if (!store.refresh_token) {
            return <Redirect to="/cabinet/login"/>
        }
        return (
            <div className={Classes.join([styles.component, 'container flex column a-center j-center'])}>
                <h2 className='mb-2'>{t('FEEDBACKS')}</h2>
                <div className={Classes.join([styles.link, "mb-6"])}>
                    <Link to='/cabinet/feedbackList'>
                        <Button theme='secondary' text='Список обращений'/>
                    </Link>
                </div>
                <div className={Classes.join([styles.img, "flex j-between"])}>
                    <img src={FeedBackImg} alt="Обратная связь" className='mr-5'/>
                    <Form theme="auth">
                        <Input
                            value={this.feedbackStore.form.title}
                            type="text" name="title"
                            placeholder={t('FEEDBACK_TITLE')} onInput={this.feedbackStore.onChange}/>
                        <textarea
                            value={this.feedbackStore.form.question}
                            onChange={(n, e) => this.feedbackStore.onChange('question', n.target.value)}
                            className='mt-2 mb-4' placeholder={t('FEEDBACK_QUESTION')}>

                        </textarea>
                        <Button
                            disabled={this.feedbackStore.formInvalid}
                            theme="secondary" text={t('SEND')}
                            type="submit" size="middle" onClick={this.send}/>
                    </Form>
                </div>
                {this.state.success && <FeedbackPopup {...this.props} t={t} status='success'
                                                      onChange={
                                                          () => {
                                                              this.setSuccess(false)
                                                              this.setRedirect(true)
                                                          }
                                                      }/>}
                {this.state.failed && <FeedbackPopup t={t} {...this.props} status='failed' onChange={
                    () => {
                        this.setFailed(false)
                        this.setRedirect(true)
                    }
                }/>}
            </div>
        )
    }
}

const FeedbackPopup = (props) => {
    const history = useHistory();

    function handleChange(event) {
        // Here, we invoke the callback with the new value
        props.onChange(false);
    }

    const closePopupRedirectToList = () => {
        console.log(history);
        props.onChange();
        props.history.push('/page')
    }
    const Success = ({onChange, t}) => (
        <div className={Classes.join([styles.success, 'flex column a-center j-center'])}>
            <img src={success} alt="Успешно!"/>
            <p className='mt-2 mb-4'>{t('FEEDBACK_POST_SUCCESS')}</p>
            <Button theme='secondary' text='Вернутся к списку' onClick={props.onChange}/>
        </div>
    )
    const Failed = ({onChange}) => (
        <div className={Classes.join([styles.failed, 'flex column a-center j-center'])}>
            <img src={failed} alt="Неудачно!"/>
            <p className='mt-2 mb-4'>Извините! Что-то пошло не так!</p>
            <Button theme='secondary' text='Вернутся к списку' onClick={props.onChange}/>
        </div>
    )
    return (
        <div className={styles.popupContainer}>
            <div className={Classes.join([styles.popup, "pt-4 pb-4 pl-3 pr-3"])}>
                {props.status === 'success' && <Success t={props.t} onChange={closePopupRedirectToList}/>}
                {props.status === 'failed' && <Failed t={props.t} onChange={closePopupRedirectToList}/>}
            </div>
            <div className={styles.popupBack} onClick={handleChange}></div>

        </div>
    )
}

export default withTranslation()(FeedbackForm);
