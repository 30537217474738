// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NLnHNxYZbGaF98-e14xCaA\\=\\= span{font-weight:bold;font-size:16px;color:rgba(0,0,0,.5);background:#f2f3f4;border-radius:5px;padding:5px 25px;margin:0px 5px}.NLnHNxYZbGaF98-e14xCaA\\=\\= span.M1GDvjETFkvjSZW3aa0ZiA\\=\\={background:#ffea00;color:#000}", "",{"version":3,"sources":["webpack://./src/views/cabinet/utils/components/CalendarYears/CalendarYears.module.scss"],"names":[],"mappings":"AACE,iCACE,gBAAA,CACA,cAAA,CACA,oBAAA,CACA,kBAAA,CACA,iBAAA,CACA,gBAAA,CACA,cAAA,CAEA,4DACE,kBAAA,CACA,UAAA","sourcesContent":[".years {\r\n  span {\r\n    font-weight: bold;\r\n    font-size: 16px;\r\n    color: rgba(0, 0, 0, 0.5);\r\n    background: #F2F3F4;\r\n    border-radius: 5px;\r\n    padding: 5px 25px;\r\n    margin:0px 5px;\r\n\r\n    &.active{\r\n      background: #FFEA00;\r\n      color:#000;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"years": "NLnHNxYZbGaF98-e14xCaA==",
	"active": "M1GDvjETFkvjSZW3aa0ZiA=="
};
export default ___CSS_LOADER_EXPORT___;
