import React from 'react';
import styles from './Banner.module.scss';
import { Container } from '../../../../components';

import banner from './assets/images/banner-background.png';
import kz from './assets/images/kz.jpg';
import { observer } from "mobx-react";
import store from "../../../../services/store";

const Banner = observer(() => {
  return (
    <Container className={styles.component}>
        {store.language === 'ru' &&  <img src={banner} alt="banner-background" />}
        {store.language === 'kk' &&  <img src={kz} alt="banner-background" />}
    </Container>
  );
});

export default Banner;
