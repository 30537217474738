import React from 'react';
import {Banner, NewsBlock} from "../utils/components";

const News = () => {
    return (
        <>
            <NewsBlock/>
        </>
    )
}

export default News;