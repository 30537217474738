// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fgzXXoeW3t914KIVGUPMFw\\=\\={padding:50px 0 80px;background-color:#ffea00}@media(max-width: 768px){.fgzXXoeW3t914KIVGUPMFw\\=\\= .oG7f1Ds-Jkyli0w8Xbjjzg\\=\\={flex-wrap:wrap;justify-content:space-around !important}}.fgzXXoeW3t914KIVGUPMFw\\=\\= .qhhOOHeaHDLNF\\+jRvbLDMQ\\=\\={width:30%;min-width:220px;padding:0px;margin:30px 0;text-align:center}@media(max-width: 768px){.fgzXXoeW3t914KIVGUPMFw\\=\\= .qhhOOHeaHDLNF\\+jRvbLDMQ\\=\\={width:47%}}@media(max-width: 480px){.fgzXXoeW3t914KIVGUPMFw\\=\\= .qhhOOHeaHDLNF\\+jRvbLDMQ\\=\\={width:100%}}.fgzXXoeW3t914KIVGUPMFw\\=\\= .qhhOOHeaHDLNF\\+jRvbLDMQ\\=\\= ._8KsISkZTYbnS7X2cIjmQOw\\=\\={font-size:52px;font-weight:700}.fgzXXoeW3t914KIVGUPMFw\\=\\= .qhhOOHeaHDLNF\\+jRvbLDMQ\\=\\= .AW\\+Wk\\+W1BEcMGQAV7IxSuA\\=\\={margin:15px 0 0;font-size:24px;font-weight:700}", "",{"version":3,"sources":["webpack://./src/views/Home/components/About/About.module.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CACA,wBAAA,CAGE,yBADF,wDAEI,cAAA,CACA,uCAAA,CAAA,CAIJ,yDACE,SAAA,CACA,eAAA,CACA,WAAA,CACA,aAAA,CACA,iBAAA,CACA,yBANF,yDAOI,SAAA,CAAA,CAEF,yBATF,yDAUI,UAAA,CAAA,CAGF,sFACE,cAAA,CACA,eAAA,CAGF,uFACE,eAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".component {\r\n  padding: 50px 0 80px;\r\n  background-color: #ffea00;\r\n\r\n  .container {\r\n    @media (max-width: 768px) {\r\n      flex-wrap: wrap;\r\n      justify-content: space-around !important;\r\n    }\r\n  }\r\n\r\n  .item {\r\n    width: 30%;\r\n    min-width: 220px;\r\n    padding: 0px;\r\n    margin: 30px 0;\r\n    text-align: center;\r\n    @media (max-width: 768px) {\r\n      width: 47%;\r\n    }\r\n    @media (max-width: 480px) {\r\n      width: 100%;\r\n    }\r\n\r\n    .title {\r\n      font-size: 52px;\r\n      font-weight: 700;\r\n    }\r\n\r\n    .text {\r\n      margin: 15px 0 0;\r\n      font-size: 24px;\r\n      font-weight: 700;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "fgzXXoeW3t914KIVGUPMFw==",
	"container": "oG7f1Ds-Jkyli0w8Xbjjzg==",
	"item": "qhhOOHeaHDLNF+jRvbLDMQ==",
	"title": "_8KsISkZTYbnS7X2cIjmQOw==",
	"text": "AW+Wk+W1BEcMGQAV7IxSuA=="
};
export default ___CSS_LOADER_EXPORT___;
