export default {
  RU: 'Русский',
  KK: 'Казахский',
  EN: 'Английский',
  REGISTER: 'Регистрация',
  NEWS: 'Новости',
  PAYMENTS: 'Платежи',
  LOGIN: 'Вход',
  LOGOUT: 'Выйти',
  ENTER: 'Войти',
  CLOSE: 'Закрыть',
  SEND: 'Отправить',
  GET_REGISTER: 'Зарегистрироваться',
  FORGET_PASSWORD: 'Забыли пароль?',
  CALCULATORS: 'Калькуляторы',
  CALCULATORS_ACTUAL_PRICING: 'Актуальные цены',
  CALCULATORS_PROBA: 'Проба',
  CALCULATORS_WEIGHT: 'Вес (гр)',
  CALCULATORS_DAYS: 'Срок (2-60 дней)',
  CALCULATORS_GOLD: 'Золото',
  CALCULATORS_CALCULATE: 'Рассчитать',
  CALCULATORS_GIVEN_AMOUNT: 'Сумма на руки',
  CALCULATORS_RETURN_AMOUNT: 'Сумма к возврату',
  FILIALS_LIST: 'Список',
  FILIALS_MAP: 'На карте',
  INFORMATION: 'Информация',
  KM: 'км.',
  PERCENT: 'Процент',
  FEEDBACKS: 'Обратная связь',
  FEEDBACKS_ADD: 'Отправить обращение',
  FEEDBACKS_OPENED: 'Ожидается ответ',
  FEEDBACKS_CLOSED: 'Есть ответ',
  FEEDBACK: 'Обращение',
  FEEDBACK_TITLE: 'Тема обращения',
  FEEDBACK_QUESTION: 'Описание',
  FEEDBACK_POST_SUCCESS: 'Обращение успешно оправлено',
  FEEDBACK_OPENED_DATE: 'Дата подачи обращения',
  FEEDBACK_ANSWER: 'Ответ',
  GOLD_CALCULATOR: 'Золото',
  TECH_CALCULATOR: 'Техника',
  TECH_CALCULATOR_INPUT_PLACEHOLDER: 'Цена за устройство',
  TECH_CALCULATOR_TECH_PERCENT_OLD: 'Б/У',
  TECH_CALCULATOR_TECH_PERCENT_NEW: 'Новое',
  TECH_CALCULATOR_CALC_AMOUNT: 'Примерная стоимость',
  BIOMETRIC: 'Использовать {{biometryType}} для входа в приложение?',
  PHONE: 'Телефон',
  PASSWORD: 'Пароль',
  EMAIL_VALIDATION_ERROR: 'Неверный формат email',
  EMAIL_LABEL:
    'Введите e-mail (В дальнейшем этот адрес будет использоваться для восстановления пароля)',
  NEW_PASSWORD: 'Новый пароль',
  PASSWORD_NOTE:
    'Пароль должен содержать заглавные и строчные латинские буквы и цифры. Длина пароля - минимум 6 символов.',
  PASSWORDS_NOT_EQUAL: 'Пароли не совпадают',
  REPEAT_PASSWORD: 'Подтвердите пароль',
  EDIT_PROFILE_NOTE: 'Задайте свой пароль для входа в личный кабинет',
  EDIT_PROFILE_SUBNOTE:
    'Пароль можно будет изменить в любое время, в личном кабинете',
  ENTER_SMS_CODE_NOTE: 'Введите код из SMS',
  ENTER_CODE: 'Введите код для быстрого доступа к приложению',
  ENTER_NEW_CODE: 'Придумайте 4-значный код для быстрого доступа к приложению',
  ENTER_BASE_WITH_AUTH: 'Использовать обычный вход',
  SET_PASSWORD: 'Установка пароля',
  YES: 'Да',
  NO: 'Нет',
  PAWNS: 'Мои залоги',
  NEXT_PAYMENT: 'следующий платеж',
  PAWN: 'Залог',
  PAWN_AMOUNT: 'Сумма займа',
  PAWN_DETAIL: 'Подробно',
  PAWN_TARIFF: 'Тариф',
  PAWN_ID: 'ID залога',
  PAWN_CREATION_DATE: 'Дата займа',
  PAWN_END_DATE: 'Дата окончания',
  PAWN_ITEMS: 'Заложено',
  PAWN_NEXTPAYMENT_DATE: 'Оплатить до',
  PAWN_NEXTPAYMENT_AMOUNT: 'Сумма задолженности на сегодня',
  PAWN_LASTPAYMENT_AMOUNT: 'Сумма задолженности на последний день кредита',
  PAY: 'Внести оплату',
  PAY_CONFIRM: 'Подтверждение',
  PAYMENT_AMOUNT: 'Сумма к оплате',
  NOTIFICATION: 'Уведомление',
  NOTIFICATIONS: 'Уведомления',
  PROFILE: 'Личный кабинет',
  PAYMENT: 'Платёж',
  PAYMENT_FAILED: 'Что-то пошло не так',
  PAYMENT_SUCCESS: 'Платёж прошел успешно',
  BACK_TO_PAWN: 'Вернуться к залогу',
  RESET_PASSWORD: 'Восстановление пароля',
  EMAIL_RESET:
    'На указанный e-mail будет отправлен код для восстановления пароля',
  CHANGE_PASSWORD: 'Change password',
  CHANGE_LANGUAGE: 'Change language',
  CHOOSE_LANGUAGE: 'Choose language',
};
